@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap');
* {
	font-family: 'M PLUS Rounded 1c', sans-serif;
}

nav {
	height: 100%;
    width: 80px;
	position: fixed;
	z-index: 1;
	top: 0;
	padding: 0 0;
	color: rgb(10, 8, 8);
	background-color: rgba(27, 82, 27, 0.836);
	display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
}

nav span {
	font-size: 18px;
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	transition-delay: 0.25s;
	margin: 1em;
	padding-bottom: 0.3em;
    display: flex;
    justify-content: center;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 1px solid white;
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--lightAccent);
}

@media screen and (max-width: 900px) {
    
    nav {
        display: flex;
        flex-direction: row;
        height: 80px;
        padding: 0px 0px;
        width: 100vw;
        position: fixed;
        z-index: 1;
        top: 0;
        color: rgb(10, 8, 8);
        background-color: rgba(27, 82, 27);
        justify-content: space-around;
        align-items: center;
        overflow-y: hidden;
        align-content: center;
        text-size-adjust: auto;
    }

    nav span {
        float: left;
        align-content: center;
        flex-direction: row;
    }
}

@media screen and (max-width: 500px) {
    
    nav {
        display: flex;
        flex-direction: row;
        height: 80px;
        padding: 0px 0px;
        width: 100vw;
        position: fixed;
        z-index: 1;
        top: 0;
        color: rgb(10, 8, 8);
        background-color: rgba(27, 82, 27);
        justify-content: space-around;
        align-items: center;
        overflow-y: hidden;
        align-content: center;
        text-size-adjust: 80%;
    }

    nav span {
        float: left;
        align-content: center;
        flex-direction: row;
    }
}