@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');

* {
	font-family: 'Varela', sans-serif;
}

/* Global Selectors */
section {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	max-width: 100%;
	border-bottom: 1px solid lightgrey;
	margin-left: 80px;
	padding: 0px 0px;
	z-index: 2;
	overflow-x: hidden;
	background-color: #e2dddd;
}

section:nth-child(even) {
	flex-direction: row-reverse;
}

/* section img, */
section div {
	height: auto;
	width: 100%;
}

section img {
	/* width: 50%; */
	padding: 0 0;
}

section div {
	display: flex;
	flex-direction: column;
	justify-content: left;
	
}


/* Home Section */

#homeDiv {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	background-image: url('/src/photos/workspace-gaf43e2be9_1920.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
}

#hi {
	font-size: 12vw;
	color: #e2dddd;
	margin: 0 0;
	width: 100%;
	padding-top : 5%;
	flex-shrink: 0;
}
#homeP {
	max-height: 40%;
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	justify-items: space-between;
	align-items: center;
	padding-right: 10vw;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 3vw;
	color: #e2dddd;
}

#homeP p {
	max-height: 30%;
	flex-grow: 0;
	margin: 0 0;
}

/* About Section */

#aboutContainer {
	justify-items: space-around;
	padding: 0 ;
}

#aboutImg {
	height: 30vw;
	width: 30vw;
}

#aboutTitle {
	font-size: 3vw;
	text-decoration: underline;
}

#aboutText {
	padding-left: 4px;
	font-size: 1.2vw;
}

/* Skills Section */
#skillsContainer {
	display: flex;
	flex-direction: column;
	background-image: url('/src/photos/programming.png');
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: hidden;
	flex-shrink: 1;
	
}

#skillsTitle {
	display: flex;
	color: #e2dddd;
	font-size: 3rem;
	flex-shrink: 1;
	
}
#skillsContainer div {
	
	background-color: #e2dddd;
	border: solid black 2px;
	border-radius: 25px;
	flex-shrink: 1;
	max-height: 25vw;

}

#skills1 {
	display: flex;
	width: 40vw;
	font-size: 1.2vw;
	justify-content: space-around;
	margin-bottom: 1rem;
	text-align: center;
}

#skills2 {
	display: flex;
	width: 40vw;
	font-size: 1.2vw;
	justify-content: space-around;
	text-align: center;
}
/* media section */
#mediaContainer {
	align-items: center;
	justify-content: center;

}
#mediaDiv {
	height: 100%;
	display: flex;
	align-items: center;
}

#mediaTitle {
	padding-top: 2rem;
	font-size: 3vw;
}

#photoDiv {
	align-items: center;
	justify-content: space-around;
	height: 70%;
	max-width: 80vw;
	overflow-y: hidden;
	overflow-x: hidden;
}
.photo {
	display: flex;
	justify-content: space-around;
	padding: 2vw;
	height: 20%;
	width: auto;
	-webkit-transition-property: all; 
	-webkit-transition-duration: 0.3s; 
	-webkit-transition-timing-function: ease; 
}

.photo:hover {
	transform: scale(1.5);

}

#contactContainer {
	display: flex;
	flex-direction: column;
	justify-content: start;
	height: 100%;
}

#contactTitle {
	font-size: 7vw;
}


#contactText {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 2vw;
}
#socials {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 3rem;
	max-height: 60vw;
	align-items: center;
	width: 75%;
	
}


#socials img {
	height: auto;
	width: 10vw;
}

#socials a:hover {
	opacity: 60%;
}


/* #contactContainer a{
	max-width: 12vw;
} */
#resume {
	align-items: center;
	font-size: 2vw;
}
#resume p {
	margin: 0;
}

#resume img {
	width: 10vw;
}

#footer {
	align-items: center;
	padding-top: 2rem;
	font-size: .75vw;
}
#footer p {
	margin: 0 0 0 0;
}

@media screen and (max-width: 900px) {
	section,
	section:nth-child(even) {
		flex-direction: column;
		margin-left: 0px;
		overflow-y: hidden;
	}
	
	
	/* section img {
		height: 10vw;
		width: auto;
		max-width: 50vw;
		padding: 10px;
	} */
	
	
	#homeDiv {
		background-image: url("/src/photos/workspacesmall.jpg");
		align-items: center;
		justify-content: center;
		
	}

	
	#aboutText {
		align-items: center;
		justify-content: center;
	}

	#aboutTitle {
		font-size: 4vw;
	}
	#aboutP {
		align-items: center;
		font-size: 3vw;
		justify-content: center;
		text-align: center;
	}

	#skillsContainer {
		justify-content: start;
		padding-top: 75px;

	}

	#skillsContainer div {
		max-height: none;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-wrap: wrap;
	}
	#skills1 {
		width: auto;
		font-size: 3vw;
		max-height: none;
		align-items: center;
	}

	#skills2 {
		width: auto;
		font-size: 3vw;
		max-height: none;
		margin-top: 100px;
	}

	#photoDiv {
		justify-content: space-around;
		align-items: center;
		height: 100vw
	}

	#mediaDiv {
		margin-top: 100px;
	}
	
	#mediaTitle {
		font-size: 6vw;
	}
	#mediaDiv img {
		height: auto;
		width: 100vw;
	}

	#photoDiv {
		height: 100%;
	}

	#social img {
		height: 10vw;
		margin-left: 0px;
		margin-right: 0px;
		margin-top: 20px;
	}
}

@media screen and (max-width: 500px) {
	section,
	section:nth-child(even) {
		flex-direction: column;
		margin-left: 0px;
	}

	/* section img {
		height: 10vw;
		width: auto;
		max-width: 50vw;
	} */

	
	#homeDiv {
		background-image: url("/src/photos/workspacesmall.jpg");
		align-items: center;
		justify-content: center;
		
	}

	#homeP {
		font-size: 6vw;
		padding: 0;
	}

	#hi {
		justify-items: center;
		
	}
	
	#aboutContainer {
		max-height: 100%;
	}

	#socials {
		display: flex;
		flex-direction: column;
		max-height: 65vw;
		align-items: center;
	}
	
}